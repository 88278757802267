<div class="navbar bg-base-300 px-4">
  <div class="flex-1">
    <a class="btn btn-ghost text-xl text-primary" [routerLink]="['/']">
      <img src="/assets/logo/logo-small.png" class="h-7" />
      <span class="mt-1">
        {{ "common.title" | translate }}
      </span>
    </a>
  </div>
  <div class="flex-none">
    <div class="dropdown dropdown-end dropdown-hover">
      <div
        tabindex="0"
        class="avatar placeholder btn btn-circle btn-ghost"
        role="button"
      >
        <div class="w-16 rounded-full bg-neutral text-neutral-content">
          <span class="text-2xl">{{ userInitials }}</span>
        </div>
      </div>
      <div
        tabindex="0"
        class="card dropdown-content card-compact z-[1] min-w-32 rounded-lg bg-base-100 p-2 text-base-content shadow"
      >
        <div class="card-body items-center">
          @if (fullName) {
            <span class="truncate">{{ fullName }}</span>
          }
          <app-logout></app-logout>

          <!-- <app-theme-switcher></app-theme-switcher> -->
        </div>
      </div>
    </div>
  </div>
</div>
