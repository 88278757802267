import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { Instruction } from "../models/instruction/instruction";
import { InstructionConfirmation } from "../models/instruction/instruction-confirmation";
import {
  FrappeApiHelper,
  mappingBuilderWithDefaultMappings,
  mappingHelper,
} from "@aht/frappe-client";

@Injectable({
  providedIn: "root",
})
export class InstructionService {
  private instructionMapping = mappingBuilderWithDefaultMappings<Instruction>(
    "SAR Instruction",
    [
      mappingHelper.stringMapper("title"),
      mappingHelper.stringMapper("group"),
      mappingHelper.dateMapper("valid_from", "validFrom"),
      mappingHelper.optionalDateMapper("valid_until", "validUntil"),
      mappingHelper.stringMapper("file"),
    ],
  );

  private instructionConfirmationMapping =
    mappingBuilderWithDefaultMappings<InstructionConfirmation>(
      "SAR Instruction Confirmation",
      [
        mappingHelper.stringMapper("user"),
        mappingHelper.stringMapper("instruction"),
        mappingHelper.booleanMapper("read"),
        mappingHelper.docStatusMapper("docstatus", "status"),
      ],
    );

  constructor(private readonly helper: FrappeApiHelper) {}

  private sortByValidFromDate(instructions: Instruction[]): Instruction[] {
    return [...instructions].sort(
      (a, b) => b.validFrom.valueOf() - a.validFrom.valueOf(),
    );
  }
  /*
   * XXX: Attention!!!
   *
   * Because of the fact that the admin and project leads also use the same frontend whilst using the backend, we can't use the default get_list methods
   * and have to use custom methods where we use get_all in the backend and filter based on the user.
   */

  loadMyInstructions(): Observable<Instruction[]> {
    return this.helper
      .callWithResults(this.instructionMapping.conversion(), {
        method: "sar.controllers.instruction_controller.my_open_instructions",
        type: "GET",
      })
      .pipe(map(this.sortByValidFromDate));
  }

  loadMyConfirmedInstructions(): Observable<Instruction[]> {
    return this.helper
      .callWithResults(this.instructionMapping.conversion(), {
        method:
          "sar.controllers.instruction_controller.my_confirmed_instructions",
        type: "GET",
      })
      .pipe(map(this.sortByValidFromDate));
  }

  loadMyInstruction(name: string): Observable<Instruction> {
    const extraParams = new Map<string, string>();
    extraParams.set("name", name);

    return this.helper.callWithResult(this.instructionMapping.conversion(), {
      method: "sar.controllers.instruction_controller.my_instruction",
      extraParams: extraParams,
      type: "GET",
    });
  }

  loadMyInstructionConfirmation(
    instruction: string,
  ): Observable<InstructionConfirmation | undefined> {
    const extraParams = new Map<string, string>();
    extraParams.set("instruction", instruction);

    return this.helper.callWithOptionalResult(
      this.instructionConfirmationMapping.conversion(),
      {
        method:
          "sar.controllers.instruction_controller.my_instruction_confirmation",
        extraParams: extraParams,
        type: "GET",
      },
    );
  }

  confirmMyInstruction(
    instruction: string,
  ): Observable<InstructionConfirmation> {
    return this.helper.callWithResult(
      this.instructionConfirmationMapping.conversion(),
      {
        method: "sar.controllers.instruction_controller.confirm_my_instruction",
        body: {
          instruction: instruction,
        },
        type: "POST",
      },
    );
  }
}
